import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import { helpTitle, back, backGroundImg, } from "./assets";
import { I18n } from "../../../components/src/languageJson/i18n";




// Customizable Area End
import SuccessConfirmController, { Props } from "./SuccessConfirmController.web";


export default class SuccessConfirm extends SuccessConfirmController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
          <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGroundImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>
            <BackBox>
              <img style={{ height: "100", width: "100%" }} src={back} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoForgotPassword} />
            </BackBox>
            <GridContainer container>
              <LeftGrid item lg={6} md={4} >
                <Box style={webStyle.boxTitle}>
                  <Box style={webStyle.subBox}>
                    <Box style={webStyle.welcomeBox}>
                      <Typography style={{
                        ...webStyle.helpTitle,
                        fontSize: 'clamp(3rem, 4vw, 4.5rem)',
                      }}>
                        {I18n.t("welcometo")}
                      </Typography>
                      <img src={helpTitle} style={webStyle.imgTit} />
                    </Box>
                    <Typography style={{
                      ...webStyle.helpSubTitle,
                      fontSize: 'clamp(1.5rem, 1.8vw, 2.2rem)',
                    }}>
                      Login To Access All our Services.
                    </Typography>
                  </Box>
                </Box>
              </LeftGrid>
              <Grid
                item
                lg={6} 
                md={8} 
                sm={12} xs={12}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <Box
                  style={{
                    maxWidth: "100%",
                    height: "937px",
                    marginTop: "100px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    style={{
                      height: "131px",
                      borderTopLeftRadius: "45px",
                      backgroundColor: "#000000",
                      borderTopRightRadius: "45px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <Box style={{ height: "74px", width: "183px" }}>
                      <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                    </Box>
                  </Box>
                  <BoxWidth608>
                    <BoxWidth>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "90%"
                        }}
                      >
                        <BoxWidth100>
                          <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px" }}>
                            <ResetTypo>{I18n.t("passwordResetTitle")}</ResetTypo>
                            <ResetSubTypo>{I18n.t("resetTitle")}</ResetSubTypo>
                          </Box>
                          <SubmitBtn
                            variant="contained"
                            data-test-id="loginBtnTestId"
                            onClick={this.NavigateToLogIn}
                          >
                            {I18n.t("login")}
                          </SubmitBtn>
                        </BoxWidth100>
                      </Box>
                    </BoxWidth>
                  </BoxWidth608>
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ResetTypo = styled(Typography)({
  fontFamily: "Poppins ",
  fontSize: "48px ",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  lineHeight: "56px ",
  marginTop: "24px",
  cursor: "pointer",
  whiteSpace: "nowrap",
  "@media(max-width: 360px)":{
    fontSize: "30px ",
  },
  "@media(max-width: 410px)":{
    fontSize: "30px ",
  },
  "@media(max-width: 610px)":{
    fontSize: "30px ",
  },
  "@media(max-width: 710px)":{
    fontSize: "30px ",
  },
});

const ResetSubTypo = styled(Typography)({
  fontFamily: "Poppins ",
  fontSize: "20px ",
  fontWeight: 400,
  lineHeight: "28px ",
  color: "#000000",
  whiteSpace: "nowrap",
  "@media(max-width: 360px)":{
    fontSize: "12px ",
  },
  "@media(max-width: 410px)":{
    fontSize: "12px ",
  },
  "@media(max-width: 610px)":{
    fontSize: "12px ",
  },
  "@media(max-width: 710px)":{
    fontSize: "10px ",
  },
})

const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});

const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "80px", left: "25px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
});

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});

const BoxWidth608 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  minHeight: "100%",
  width: "608px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const BoxWidth = styled(Box)({
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const GridContainer = styled(Grid)({
  height: "100vh",
  width: "80vw",
  '@media (max-width:1020px)': {
    justifyContent: "center",
  },
});

const BoxWidth100 = styled(Box)({
  width: "100%",
  display:"flex",
  flexDirection:"column",
  gap:"40px",
});

const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },

  fontFamily: "Poppins",
  fontSize: "56px ", display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  fontWeight: 600, marginBottom: "9px ",
  color: "#0F172A",


});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },
  display: "flex", justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ", fontSize: "22px ",
  fontWeight: 400, color: "#0F172A",
  marginBottom: "64px",


});

const ErrorMsg = styled(Box)({
  "@media (max-width:1350px )": {
    width: "500px"
  },
  "@media (max-width:1100px )": {
    width: "426px"
  },
  "@media (max-width:500px )": {
    width: "80%"
  },
  // width: "623px"

});

const webStyle = {
  boxTitle: {
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  subBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "64px",
    width: "100%",
    margin: "auto",
    textAlign: "center" as "center"
  },
  welcomeBox: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    justifyContent: "center",
    flexWrap: "wrap" as "wrap"
  },
  helpTitle: {
    fontFamily: "Poppins",
    fontSize: "58px", lineHeight: "24px",
    fontWeight: 600, color: "#FFFFFF",
    whiteSpace: "nowrap"
  },
  imgTit: {
    height: "74px",
    width: "183px",
    textAlign: "center" as "center"
  },
  sign: {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "30px ",
    alignItems: "center",
    lineHeight: "40px",
    color: "#000000",
    marginTop: "24px",
    marginBottom: "24px",
    justifyContent: "center",
  },
  helpSubTitle: {
    fontFamily: "Poppins",
    fontSize: "30px",
    lineHeight: "48px",
    fontWeight: 400,
    color: "#FFFFFF",
    whiteSpace: "nowrap"
  },
}


const SubmitBtn = styled(Button)({
  width: "100%",
  height: "64px",
  padding: "16px",
  borderRadius: "8px",
  gap: "8px",
  color: "#FFF",
  background: "#527FE2",
  fontFamily: "Poppins ",
  fontSize: "20px ",
  fontWeight: 600,
  lineHeight: "24px",
  cursor: "pointer",
  textTransform: "none",
  marginTop: "48px",
  '&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
});

const styles: { [key: string]: React.CSSProperties } = {

  apiForgotSuccess: {
    borderLeft: "4px solid #059669",
    padding: "12px", backgroundColor: "#D1FAE5",
    marginBottom: "24px",
    // width:"623px"
  },
  apiForgotFalied: {
    borderLeft: "4px solid #DC2626", padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px",
    // width:"623px"
  },
  buttonStyle: {
    background:
      "#527FE2",
    width: "647px",
    height: "61px", padding: "16px",
    gap: "8px",
    borderRadius: "8px", color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600, lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none"
  },
  accountStyleBold: {
    fontFamily: "Poppins ",
    fontSize: "16px ", cursor: "pointer",
    lineHeight: "24px",
    color: "#527FE2",
  },

  resend: {
    display: 'flex', textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap', fontSize: '14px',
    width: '100%', margin: '30px 0px',
    fontWeight: '600'
  },
  resendBtn: {
    marginRight: '4px'
  },
};


// Customizable Area End
