import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { emailImg, passImg, helpTitle, checkedCheck, unChecked, backGround, backbutton, greenUser, userImg, otpImg } from "./assets";
import PhoneInput from "react-phone-number-input";
import OtpInput from 'react-otp-input';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "react-phone-number-input/style.css";
import { green } from "@material-ui/core/colors";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import LanguageIcon from "@material-ui/icons/Language";
import { I18n } from "../../../components/src/languageJson/i18n";
import { Link } from "react-router-dom";


// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";



export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  styles: { [key: string]: React.CSSProperties } = {
    inActiveButton: {
      fontWeight: 400,
      margin: "0 7px",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      fontFamily: "Poppins, sans-serif",
      fontSize: "14px",
      textTransform: "none",
      color: "#000",
      backgroundColor: "#fff",
      cursor: "pointer",
      textAlign: this.getValueBasedOnLanguage("right", "left")
    },
    activeButton: {
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      margin: "0 10px",
      fontFamily: "Poppins, sans-serif",
      fontSize: "14px",
      textTransform: "none",
      color: "#527FE2",
      backgroundColor: "#E0E7FF",
      cursor: "pointer",
      textAlign: this.getValueBasedOnLanguage("right", "left")
    },
    checkBox: {
      borderRadius: '8px',
      width: '20px',
      height: '20px',
      border: '1px solid #527FE2',
    }
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      <Box style={{ width: "100vw", minHeight: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGround})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>

        <BackBox>
          <img style={{ height: "100", width: "100%" }} src={backbutton} alt="backButton..." onClick={this.NavigatetoRegistration}/>
        </BackBox>

        <Grid container style={{ width: "80vw" }}>
          <LeftGrid
            item
            lg={6}
            md={6}
          >
            <Box style={{ display: "flex", justifyContent: "center", height: "100%" }}>
              <Box style={{ display: "flex", flexDirection: "column", gap: "64px", width: "100%", margin: "auto", textAlign: "center" }}>
                <Box style={{ display: "flex", alignItems: "center", gap: "16px", justifyContent: "center" }}>
                  <TypoWelcome >{I18n.t("welcometo")} </TypoWelcome>
                  <img src={helpTitle} style={webStyle.imgTit} />
                </Box>
                <SubWelcomeTitle>{I18n.t("subTitle")} </SubWelcomeTitle>
              </Box>
            </Box>

          </LeftGrid>

          {!this.state.otpModal ? (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={10}
              style={{
                display: "flex",
                width: "100%", height: "100%",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <Box style={{ height: "984px", maxWidth: "100%", marginTop: "100px", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                <Box
                  style={{ height: "131px", backgroundColor: "#000000", borderTopLeftRadius: "45px", borderTopRightRadius: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box style={{ height: "74px", width: "183px" }}>
                    <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                  </Box>

                </Box>
                <BoxWidth647>
                  <Box style={{ width: "90%" }}>
                    <Typography style={webStyle.sign}>{I18n.t("signUp")}</Typography>
                    <InputField
                      id="name"
                      placeholder={I18n.t("userName")}
                      variant="outlined"
                      fullWidth
                      value={this.state.userName}
                      data-test-id="userNameTestID"
                      className={this.getErrorMessageClassName(this.state.errorMessageUserName, this.state.userName)}
                      onChange={(e) => this.handleUser(e.target.value)}
                      InputProps={{
                        style: {
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr")
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={this.handleUserImage(greenUser, userImg)}
                              alt="user icon"
                              style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                objectFit: 'contain',
                                display: 'block'
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box>
                      {this.getErrorMessage() && (
                        <Box
                          style={{
                            borderLeft: "4px solid red",
                            padding: "12px",
                            backgroundColor: "#FEE2E2",
                            marginBottom: "24px",
                          }}
                        >
                          <Typography
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr")
                            }}
                          >
                          {this.handleAllError()}
                          </Typography>
                          {this.state.errorMessagePrivacy}
                        </Box>
                      )}

                      <div style={{ position: "relative" }}>
                        {!this.state.phone && (
                          <label
                            style={{
                              position: "absolute",
                              top: 18,
                              left: 100,
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "normal",
                              lineHeight: "24px",
                              color: "#94A3B8",
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr")
                            }}
                            htmlFor=""
                          >
                            {I18n.t("placeHolderNumber")}
                          </label>
                        )}

                        <PhoneStyle
                          data-test-id="phoneTestID"
                          className={this.handlePhonerror()}
                          value={this.state.phone}
                          onChange={(e: any) => {
                            this.handlePhoneNumber(e);
                          }}
                          phone
                          defaultCountry="AE"
                          countries={[]}
                          international
                          countryCallingCodeEditable={false}
                          borderColor={"#CBD5E1"}
                          style={{
                            textAlign: this.getValueBasedOnLanguage("right", "left"),
                            direction: this.getValueBasedOnLanguage("rtl", "ltr")
                          }}
                        />
                      </div>

                      <InputField
                        style={{ height: "56px" }}
                        id="email"
                        placeholder={I18n.t("email")}
                        data-test-id="emailTestID"
                        value={this.state.email}
                        onChange={(e) => this.handleEmail(e.target.value)}
                        className={this.handleEmailerror()}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            textAlign: this.getValueBasedOnLanguage("right", "left"),
                            direction: this.getValueBasedOnLanguage("rtl", "ltr")
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={this.handleEmailErrorImg(this.state.errorMessageEmail, this.state.email, emailImg)}
                                alt="status icon"
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  objectFit: 'contain',
                                  display: 'block'
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box>
                        <InputField
                          style={{ height: "56px" }}
                          data-test-id="passwordTestID"
                          id="password"
                          placeholder={I18n.t("password")}
                          onChange={(e) => this.handlePassword(e.target.value)}
                          className={this.handlePasswordError()}
                          value={this.state.password}
                          fullWidth
                          type={this.handlePassTypeCheck(this.state.passwordVisible)}
                          variant="outlined"
                          InputProps={{
                            style: {
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr")
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={this.handlePassErrorImg(this.state.errorMessagePassword, this.state.password, passImg)}
                                  alt="user icon"
                                  style={{
                                    width: '24px !important',
                                    height: '24px !important',
                                    objectFit: 'contain',
                                    display: 'block'
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="showpassword"
                                  onClick={this.handleClickShowPassword}
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onMouseDown={this.handleMouseDownPassword}
                                >

                                  {this.state.passwordVisible ? (
                                    <Visibility
                                      style={{ color: (this.handleVisibilityOn()) }}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      style={{ color: (this.handleVisibilityOff()) }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "24px",
                            }}
                          >

                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {[
                                {
                                  text: `${I18n.t("oneLowercaseLetter")}`,
                                  isMet: this.state.conditionsMet.hasLowercase,
                                },
                                {
                                  text: `${I18n.t("oneNumber")}`,
                                  isMet: this.state.conditionsMet.hasNumber,
                                },
                                {
                                  text: "Be at least 8 characters",
                                  isMet: this.state.conditionsMet.isLongEnough,
                                },
                              ].map((item, index) => (
                                <Typography
                                  key={index}
                                  style={styles.passwordHeadingItems}
                                >
                                  <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checkedCheck, unChecked)} alt="Tick Icon" />
                                  {item.text}
                                </Typography>
                              ))}
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {[
                                {
                                  text: `${I18n.t("oneUppercaseLetter")}`,
                                  isMet: this.state.conditionsMet.hasUppercase,
                                },

                                {
                                  text: `${I18n.t("specChar")}`,
                                  isMet: this.state.conditionsMet.hasSpecialChar,
                                },
                              ].map((item, index) => (
                                <Typography
                                  key={index}
                                  style={styles.passwordHeadingItems}
                                >
                                  <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checkedCheck, unChecked)} alt="Tick Icon" />
                                  {item.text}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <InputField
                          style={{ height: "56px" }}
                          id="confirm-password"
                          data-test-id="confirmPasswordTestID"
                          placeholder={I18n.t("confirmPassword")}
                          value={this.state.confirmPassword}
                          onFocus={this.handleFocus}
                          onBlur={this.onUnFocused}
                          onChange={(e) => this.handlePasswordConfirm(e.target.value)}
                          className={this.handleConfPass()}
                          type={this.handlePasswordType(this.state.confirmPasswordVisible)}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            style: {
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr")
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={this.handleConfPassImg(this.state.errorMessageConfirm, this.state.confirmPassword, passImg)}
                                  alt="user icon"
                                  style={{
                                    width: '24px !important',
                                    height: '24px !important',
                                    objectFit: 'contain',
                                    display: 'block'
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                  onMouseDown={this.handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {this.state.confirmPasswordVisible ? (
                                    <Visibility
                                      style={{
                                        color: (this.handleVisibilityOnConf())
                                      }}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      style={{
                                        color: (this.handleVisibilityOffConf())
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Typography style={styles.termsHeading}>
                          <input
                            type="checkbox"
                            checked={this.state.checked}
                            onChange={this.handleCheckboxChange}
                            style={{ display: 'none' }}
                          />
                          <Box data-test-id="checkUncheck" style={{ height: "20px", width: "20px" }} {...label} onClick={this.showCheckedUnchecked}>
                            <img src={this.state.checked ? checkedCheck : unChecked} style={{ height: "100%", width: "100%" }} />
                          </Box>
                          {I18n.t("acceptTerms")}
                          <span data-test-id="termsmodal" style={styles.termsHeadingBold} onClick={this.handleShowTerms}>
                            {I18n.t("termsAndConditions")}
                          </span>
                          {"&"}
                          <span data-test-id="privacPolicy" style={styles.termsHeadingBold} onClick={this.handleShowPrivacyPolicy}>
                            {I18n.t("privacypolicy")}
                          </span>

                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        style={styles.buttonStyle}
                        data-test-id="nextBtnTestId"
                        onClick={this.handleAllValidation}
                      >
                        {I18n.t("signUp")}
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <Typography style={styles.accountStyle} >
                      {I18n.t("alreadyHaveAccount")}{" "}
                      <Typography style={styles.accountStyleBold} onClick={this.goToLOginSCreen} >{` ${I18n.t("login")}`}</Typography>
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      marginTop: "50px",
                      alignItems: "center",
                      display: "flex", justifyContent: "center",
                      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
                      margin: "10px 0",
                    }}
                  >
                    <IconButton>
                      <LanguageIcon />
                    </IconButton>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        margin: "0 5px",
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        fontSize: "16px",
                        cursor: "pointer",
                        color: "#555",
                        fontWeight: 400,
                      }}
                    >
                      {I18n.t("languageLabel")}
                    </Typography>
                    <Button
                      data-test-id="englishSwtich"
                      style={this.state.currentLanguage === "en"
                        ? this.styles.activeButton
                        : this.styles.inActiveButton
                      }
                      onClick={this.switchToEnglish}
                    >
                      English
                    </Button>
                    <Button
                      data-test-id="arabicSwtich"
                      style={this.state.currentLanguage === "ar"
                        ? this.styles.activeButton
                        : this.styles.inActiveButton
                      }
                      onClick={this.switchToArabic}
                    > عربي
                    </Button>
                  </Box>
                </BoxWidth647>
              </Box>

            </Grid>

          ) : (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={10}
              style={{
                display: "flex",
                width: "100%", height: "100%",
                justifyContent: "center",
                alignItems:"center",
              }}
            >
              <OtpBox>
                <Pappper elevation={3}>
                  <Box>
                    <TitleTypo>
                      Please enter the OTP sent to your phone to continue.
                    </TitleTypo>
                  </Box>
                  <Box style={{ height: "122px", width: "160px" }}>
                    <img src={otpImg} style={{ height: "100%", width: "100%" }} />
                  </Box>
                  <Box>
                    <Typography gutterBottom style={styles.headerOtp}>
                      Enter OTP Code
                    </Typography>
                    <OtpInput
                      inputType="tel"
                      value={this.state.otpValue}
                      onChange={this.handleOtpChange}
                      containerStyle={{ outline: "none", gap: "24px" }}
                      data-test-id="otpinput"
                      numInputs={4}
                      renderInput={(props) => <input {...props} placeholder="-"
                        style={{
                          ...props.style,
                          borderColor: (this.checkOtpError())
                        }}
                        disabled />}
                      inputStyle={styles.otpInputt}
                    />
                  </Box>
                  <Box style={{width:"100%"}}>
                    <SubmitButton
                      onClick={this.handleConfirmOtp}
                      data-test-id="nextBtnTestIdotp"
                    >
                      Submit
                    </SubmitButton>
                  </Box>
                  <Box data-test-id="resetpassword">
                    <Typography variant="body2" style={styles.resendLink}>
                      <Link to="/" style={styles.resended}>Resend</Link> OTP Code
                    </Typography>
                  </Box>
                </Pappper>
              </OtpBox>
            </Grid>
          )}

          {(this.state.showTermsModal || this.state.showPolicyModal) &&
            <TermsConditions navigation={""} id={""}
              showTermsModal={this.state.showTermsModal}
              handleHideTerms={this.handleHideTerms}
              handleCheckboxAccept={this.handleCheckboxAccept}
              handleCheckboxReject={this.handleCheckboxReject}

              showPolicyModal={this.state.showPolicyModal}
              handleHidePrivacyPolicy={this.handleHidePrivacyPolicy}
              handlePrivacyPolicyAccept={this.handlePrivacyPolicyAccept}
              handlePrivacyPolicyReject={this.handlePrivacyPolicyReject}
            />}
        </Grid>
      </Box>


      // Customizable Area End
    );
  }
}
// Customizable Area Start

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "80px", left: "25px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
})

const OtpBox =  styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '700px',
  width: "600px",
  textAlign: "center",
  "@media (max-width: 1280px)": {
    height:"600px",
    width: '500px',
  },
})

const Pappper = styled(Paper)({
  width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap:"40px",
    padding:"40px",
})

const TitleTypo = styled(Typography)({
  textAlign: 'center',
  fontWeight: 600,
  fontSize:"36px",
  fontFamily:"Poppins",
  color:"#0F172A",
  lineHeight:"40px",
  "@media (max-width: 1280px)": {
    fontSize:"33px",
  },
  "@media (max-width: 780px)": {
    fontSize:"30px",
    lineHeight:"35px",
  },
  "@media (max-width: 610px)": {
    fontSize:"30px",
    lineHeight:"35px",
  },
})

const SubmitButton = styled(Button)({
  height: "61px",
  width: '100%',
    backgroundColor: "#527FE2",
    textTransform: "capitalize",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "20px",
    fontFamily: "Poppins",
    lineHeight: "26px",
    fontWeight: 600,
    "&:hover":{
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
})

const TypoWelcome = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "58px",
  lineHeight: "24px",
  fontWeight: 600,
  color: "#FFFFFF",
  whiteSpace: "nowrap",
  "@media (min-width:1000px) and (max-width:1280px)": {
    fontSize: "35px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    fontSize: "40px"
  }
})

const SubWelcomeTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "30px",
  lineHeight: "48px",
  fontWeight: 400,
  color: "#FFFFFF",
  whiteSpace: "nowrap",
  "@media (min-width:1000px) and (max-width:1280px)": {
    fontSize: "18px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    fontSize: "24px"
  }
})

const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    width: "410px"
  },
})

const EnglishButton = styled(Button)({
  margin: "0 5px",
  borderRadius: "20px",
  border: "1px solid #ddd",
  padding: "5px 15px",
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  textTransform: "none",
})

const ArabichButton = styled(Button)({
  margin: "0 5px",
  borderRadius: "20px",
  border: "1px solid #ddd",
  padding: "5px 15px",
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  textTransform: "none",
})

const InputField = styled(TextField)({

  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  marginBottom:
    "1rem",
  height: "3.5rem",

  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    color:
      "#334155",
    textTransform: "uppercase",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    lineHeight: "18px",
    fontSize: "12px",

    fontWeight: "normal",

    color: "#DC2626",
    fontFamily: "Poppins",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor:
      "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",

  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0.5rem",
    borderWidth:
      "1px",

    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",

    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    maxWidth: "unset",
    minWidth: "unset",

  },
  "&.success .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6EE7B7 !important",
  },
  "&.success .MuiInputBase-input": {
    color: "#6EE7B7 !important",
  },
  "&.red .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FCA5A5 !important",
  },
  "&.red .MuiInputBase-input": {
    color: "#FCA5A5 !important",
  },
});

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});


const ImageGrid = styled(Grid)({
  "@media (max-width:899px )": {
    display: "none",
  },
});

const styles: any = {
  passwordHeadingItems: {
    gap: "6px",
    fontSize:
      "12px",
    fontFamily:
      "Poppins ",
    display: "flex",
    alignItems:
      "center",
    fontWeight:
      400,
    lineHeight: "24px ",
    cursor:
      "pointer",
  },
  termsHeading: {
    fontSize: "14px ",
    lineHeight: "24px ",
    cursor: "pointer",
    fontFamily: "Poppins ",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontWeight: 400,
    whiteSpace: "wrap"
  },
  termsHeadingBold: {
    fontSize: "14px ",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "Poppins ",
    textDecoration: "underline",
  },
  buttonStyle: {
    fontFamily: "Poppins ",
    fontSize: "20px ",
    lineHeight: "24px",
    fontWeight: 600,
    height: "64px",
    background: "#527FE2",
    width: "100%",
    gap: "8px",
    padding: "16px",
    borderRadius: "8px",
    color: "white",
    marginTop: "20px",
    cursor: "pointer",
    textTransform: "capitalize"
  },
  accountStyle: {
    fontFamily:
      "Poppins ",
    fontSize: "16px ",
    display: "flex",
    flexDirection: "row"


  },
  accountStyleBold: {
    lineHeight: "24px",
    fontSize: "16px ",
    cursor: "pointer",
    marginLeft: "4px",

    color:
      "#527FE2",
    fontFamily: "Poppins ",
  },
  headerOtp:{
    textAlign: 'center',
    fontWeight: 700,
    fontSize:"30px",
    fontFamily:"Poppins",
    color:"#0F172A",
    lineHeight:"40px"
  },
  inputBox: {
    display: 'flex',
    justifyContent: 'center',
    gap:"24px"
  },
  otpInputt: {
    width: 73,
    height: 73,
    textAlign: 'center',
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  resendLink: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize:"18px",
    fontFamily:"Poppins",
    color:"#0F172A",
    lineHeight:"26px"
  },
  resended:{
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 700,
    fontSize:"18px",
    fontFamily:"Poppins",
    color:"#527FE2",
    lineHeight:"26px",
    textDecoration:"none",
  }

};
export const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
  height: 56,
  alignItems: "center",
  borderRadius: 8,
  zIndex: 1,
  marginBottom: "20px",
  border: `1px solid ${borderColor || "#CBD5E1"}`,
  display: "flex",
  position:
    "relative",


  paddingLeft:
    10,
  "& input": {
    border: "none",
  },
  "& input:focus": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputInput": {
    color: "#334155",
    fontFamily: "Poppins",

    fontWeight: 400, background: "transparent",
    fontSize: "16px", lineHeight: "24px",
  },
  "& input:focus-visible": {
    border: "none", outline:
      "none",
  },

}));

export const PhoneStyleAR = styled(PhoneInput)(({ borderColor }: any) => ({
  height: 59,
  alignItems: "center",
  borderRadius: 8,
  zIndex: 1,
  marginBottom: "24px",
  border: `1px solid ${borderColor || "#CBD5E1"}`,
  display: "flex",
  position:
    "relative",


  paddingLeft:
    10,
  "& input": {
    border: "none",
  },
  "& input:focus": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputInput": {
    color: "#334155",
    fontFamily: "Poppins",

    fontWeight: 400, background: "transparent",
    fontSize: "16px", lineHeight: "24px",
    direction: 'ltr',
    textAlign: "end"
  },
  "& input:focus-visible": {
    border: "none", outline:
      "none",
  },
}));

const webStyle = {
  imgTit: {
    height: "74px",
    width: "183px", textAlign: "center" as "center"
  },
  sign: {
    fontFamily: "Poppins",
    fontSize: "30px ",
    alignItems: "center",
    fontWeight: 700,
    justifyContent: "center",
    lineHeight: "40px",
    color: "#000000",
    marginBottom: "20px",
    marginTop: "20px",
    textAlign: "center" as "center"
  },
  
}

// Customizable Area End
