export const card = require("../assets/card.png");
export const vat = require("../assets/vat.png");
export const home = require("../assets/home.png");
export const email = require("../assets/email.png");
export const auth = require("../assets/auth.png");
export const taxino = require("../assets/taxino.png");
export const authId = require("../assets/authId.png");
export const iban = require("../assets/Iban.png");
export const branch = require("../assets/branch.png");
export const categories = require("../assets/categories.png");
export const location = require("../assets/location.png");
export const ophours = require("../assets/ophours.png");
export const topdesign = require("../assets/topdesign.png");
export const attatchfile = require("../assets/attatchfile.png");
export const helpicon = require("../assets/helpIcon.png");
export const time = require("../assets/time.png");
export const deleteimg = require("../assets/delete.png");
export const checkedpng = require("../assets/checked.png");
export const uncheckedpng = require("../assets/unchecked.png");