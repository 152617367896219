import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { passImg, helpTitle, back, backGroundImg, unChecked, checked } from "./assets";
import { I18n } from "../../../components/src/languageJson/i18n";



const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "80px", left: "25px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
});

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});

const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  minHeight: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const BoxWidth = styled(Box)({
  flexDirection: "column",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  width:"100%",
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const GridContainer = styled(Grid)({
  height: "100vh",
  width: "80vw",
  '@media (max-width:1020px)': {
    justifyContent: "center",
  },
});

const webStyle = {
  boxTitle: {
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  subBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "64px",
    width: "100%",
    margin: "auto",
    textAlign: "center" as "center"
  },
  welcomeBox: {
    display: "flex", 
    alignItems: "center", 
    gap: "16px", 
    flexWrap: "wrap" as "wrap"
  },
  helpTitle: {
    fontFamily: "Poppins",
    fontSize: "58px", lineHeight: "24px",
    fontWeight: 600, color: "#FFFFFF",
    whiteSpace: "nowrap"
  },
  imgTit: {
    height: "74px",
    width: "183px",
    textAlign: "center" as "center"
  },
  sign: {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "30px ",
    alignItems: "center",
    lineHeight: "40px",
    color: "#000000",
    marginTop: "24px",
    marginBottom: "24px",
    justifyContent: "center",
  },
  helpSubTitle: {
    fontFamily: "Poppins",
    fontSize: "30px",
    lineHeight: "48px",
    fontWeight: 400,
    color: "#FFFFFF",
    whiteSpace: "wrap",
    textAlign:"left" as "left",
  },
}

const BoxWidth100 = styled(Box)({
  width: "90%",
  "@media (min-width:410px) and (max-width:600px)": {
    width: "80%"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "75%"
  }
});

const ForgotTypo = styled(Typography)({
  fontFamily: "Poppins ",
  fontSize: "48px ",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  lineHeight: "56px ",
  marginTop: "24px",
  cursor: "pointer",
  whiteSpace: "nowrap",
"@media(max-width:1280px)":{
  fontSize:"41px"
},
"@media(max-width:1380px)":{
  fontSize:"42px"
},
})

const ForgotSubTypo = styled(Typography)({
  fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 400,
    lineHeight: "28px ",
    color: "#000000",
    whiteSpace: "nowrap",
    "@media(max-width:1280px)":{
      fontSize:"18px"
    },
    "@media(max-width:1380px)":{
      fontSize:"19px"
    },
})


// Customizable Area End


import NewPasswordController, { Props } from "./NewPasswordController.web";
import { ArrowBackIos, Visibility, VisibilityOff } from "@material-ui/icons";



export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
          <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGroundImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>
            <BackBox>
              <img style={{ height: "100", width: "100%" }} src={back} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoForgotPassword} />
            </BackBox>
            <GridContainer container>
              <LeftGrid
                item  lg={6} md={4} >
                <Box style={webStyle.boxTitle}>
                  <Box style={webStyle.subBox}>
                    <Box style={webStyle.welcomeBox}>
                      <Typography style={{
                        ...webStyle.helpTitle,
                        fontSize: 'clamp(3rem, 4vw, 4.5rem)',
                      }}>
                        {I18n.t("welcometo")}
                      </Typography>
                      <img src={helpTitle} style={webStyle.imgTit} />
                    </Box>
                    <Typography style={{
                      ...webStyle.helpSubTitle,
                      fontSize: 'clamp(1.5rem, 1.8vw, 2.2rem)',
                    }}>
                      {I18n.t("loginTitle")}
                    </Typography>
                  </Box>
                </Box>
              </LeftGrid>
              <Grid
                item
                lg={6} md={8} sm={12} xs={12}
                style={{
                  display: "flex", 
                  width: "100%", 
                  // height: "100%",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Box 
                style={{ 
                  maxWidth: "100%", 
                  height: "937px", 
                  overflow: "hidden" ,
                  marginTop:"100px",
                  }}
                  >
                  <Box
                    style={{ 
                      height: "131px", 
                      backgroundColor: "#000000", 
                      borderTopLeftRadius: "45px", 
                      borderTopRightRadius: "45px", 
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center" 
                      }}>
                    <Box style={{ height: "74px", width: "183px" }}>
                      <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                    </Box>
                  </Box>
                  <BoxWidth647>
                    <BoxWidth>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%"
                        }}
                      >
                        <BoxWidth100>
                          <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px" }}>
                            <ForgotTypo> Create New Password</ForgotTypo>
                            <ForgotSubTypo>Create a Strong Password to Secure Your Account.</ForgotSubTypo>
                          </Box>
                          {this.state.apiResponseMessage && (
                            <ErrorMsg
                              style={styles.apiForgotFalied}
                            >
                              <Typography data-test-id="apiResponseMessage">
                                {this.state.apiResponseMessage}
                              </Typography>
                            </ErrorMsg>
                          )}

                          {this.state.apiResponsesuccessMessage && (
                            <ErrorMsg
                              style={styles.apiForgotSuccess}
                            >
                              <Typography data-test-id="apiResponsesuccessMessage">
                                {this.state.apiResponsesuccessMessage}
                              </Typography>
                            </ErrorMsg>
                          )}

                          <InputField
                            data-test-id="passTestID"
                            id="password"
                            placeholder="Password"
                            onChange={(event) => this.handlePassword(event.target?.value)}
                            value={this.state.newPassword}
                            fullWidth
                            type={this.state.passwordVisible ? "" : "password"}
                            variant="outlined"
                            error={this.state.errorColorshow}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passImg} alt="Password Icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    data-test-id="showpassword"
                                    onClick={this.handleShowPassword}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    {this.state.passwordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />

                          <Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "24px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "6px"
                                }}
                              >
                                {[
                                  {
                                    text: "At least one small letter ",
                                    isMet: this.state.conditionsMet.hasLowercase,
                                  },
                                  {
                                    text: "At least one number",
                                    isMet: this.state.conditionsMet.hasNumber,
                                  },
                                  {
                                    text: "Be at least 8 characters",
                                    isMet: this.state.conditionsMet.isLongEnough,
                                  },
                                ].map((item, index) => (
                                  <Typography
                                    key={index}
                                    style={styles.passwordHeadingItem}
                                  >
                                    <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checked, unChecked)} alt="Tick Icon" />
                                    {item.text}
                                  </Typography>
                                ))}
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "6px",
                                }}
                              >
                                {[
                                  {
                                    text: "At least one capital letter",
                                    isMet: this.state.conditionsMet.hasUppercase,
                                  },
                                  {
                                    text: "At least one special letter",
                                    isMet: this.state.conditionsMet.hasSpecialChar,
                                  },
                                ].map((item, index) => (
                                  <Typography
                                    key={index}
                                    style={styles.passwordHeadingItem}
                                  >
                                    <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checked, unChecked)} alt="Tick Icon" />
                                    {item.text}
                                  </Typography>
                                ))}
                              </Box>
                            </Box>

                          </Box>
                          <InputField
                            id="confirm-password"
                            data-test-id="confirmPassTestID"
                            placeholder="Confirm Password"
                            value={this.state.confirmPassword}
                            onChange={(event) => this.handleConfirmPassword(event.target?.value)}
                            type={this.state.confirmPasswordVisible ? "" : "password"}
                            variant="outlined"
                            fullWidth
                            error={this.state.errorColorshow}
                            InputProps={{

                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passImg} alt="Password Icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleShowConfirmPassword}
                                    edge="end"
                                  >
                                    {this.state.confirmPasswordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <SubmitBtn
                            variant="contained"
                            // style={styles.buttonStyle}
                            data-test-id="submitBtnTestId"
                            onClick={this.handleAllValidation}
                          >
                            Submit
                          </SubmitBtn>

                        </BoxWidth100>
                      </Box>
                    </BoxWidth>
                  </BoxWidth647>
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const InputField = styled(TextField)({
  marginBottom: "24px",
  fontSize: "18px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "25px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1", borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px", borderColor: "#6EE7B7",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px", marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset", maxWidth: "unset",
  },
});

const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },

  fontFamily: "Poppins",
  fontSize: "56px ", display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  fontWeight: 600, marginBottom: "9px ",
  color: "#0F172A",


});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },
  display: "flex", justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ", fontSize: "22px ",
  fontWeight: 400, color: "#0F172A",
  marginBottom: "64px",


});

const ErrorMsg = styled(Box)({
  "@media (max-width:1350px )": {
    width: "500px"
  },
  "@media (max-width:1100px )": {
    width: "426px"
  },
  "@media (max-width:500px )": {
    width: "80%"
  },
  // width: "623px"

});


const SubmitBtn = styled(Button)({
  background: "#527FE2",
  width: "100%",
  height: "56px",
  padding: "16px",
  gap: "8px", borderRadius: "8px",
  color: "#FFF",
  marginTop: "48px",
  fontFamily: "Poppins ",
  fontSize: "20px ", fontWeight: 600,
  lineHeight: "24px", cursor: "pointer",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  }
});

const styles: { [key: string]: React.CSSProperties } = {
  passwordHeadingItem:{
    display: "flex",
    gap:'6px',
    alignItems: "center",
    fontSize: "12px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    cursor: "pointer"
  },
  apiForgotSuccess: {
    borderLeft: "4px solid #059669",
    padding: "12px", backgroundColor: "#D1FAE5",
    marginBottom: "24px",
    // width:"623px"
  },
  apiForgotFalied: {
    borderLeft: "4px solid #DC2626", padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px",
    // width:"623px"
  },
  buttonStyle: {
    background:
      "#527FE2",
    width: "647px",
    height: "61px", padding: "16px",
    gap: "8px",
    borderRadius: "8px", color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600, lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none"
  },
  accountStyleBold: {
    fontFamily: "Poppins ",
    fontSize: "16px ", cursor: "pointer",
    lineHeight: "24px",
    color: "#527FE2",
  },

  resend: {
    display: 'flex', textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap', fontSize: '14px',
    width: '100%', margin: '30px 0px',
    fontWeight: '600'
  },
  resendBtn: {
    marginRight: '4px'
  },
};


// Customizable Area End
